import styles from 'navigation/_navigation.scss';
import { throttle } from 'throttle';

var millis = parseInt(styles.animationMillis);

const MIN_WIDTH = 768;

var Navigation = (function ($) {
  var $site = $('#site');
  var $content = $('#main-content');
  var is_open = false;
  var $toggles = $('.toggle-mobile-menu');

  function open() {
    $(window).trigger('navigation:open');
    $('body').addClass('mobile-nav-sliding');

    var windowWidth = $(window).width();

    if (windowWidth >= MIN_WIDTH) {
      var scroll_percentage = ($(window).scrollTop() / ($(document).height() - $(window).height()));
      var scale_factor = ($content.outerWidth() - $('#flyout-nav').outerWidth()) / $content.outerWidth();

      $content.css({
        'transform': `scale(${scale_factor})`,
        'transform-origin': `0 ${$content.height() * scroll_percentage}px`
      });
    } else {
      $content.css({
        'transform': `translateX(${-1 * $('#flyout-nav').outerWidth()}px)`
      });
    }

    $('body').addClass('mobile-nav-open');
    $('body').addClass('mobile-nav-expanded');

    var afterSlide = function () {
      $('body').removeClass('mobile-nav-sliding');

      if (windowWidth >= MIN_WIDTH) {

        var height = $content.outerHeight() * scale_factor;

        $site.css({ 'height': `${height}px` });

        $content.css({ 'transform-origin': "0 0" });

        window.scrollTo(0, (height - window.innerHeight) * scroll_percentage);
      }

      $('#flyout-nav').focus();

      $(window).trigger('navigation:opened');
    }

    if (is_open) {
      afterSlide();
    } else {
      setTimeout(afterSlide, millis);
    }

    is_open = true;
  }

  function close() {
    $(window).trigger('navigation:close');
    $('body').addClass('mobile-nav-sliding');
    $('#flyout-nav').attr('tabindex', '-1');

    var scroll_percentage = ($(window).scrollTop() / ($(document).height() - $(window).height()));

    $('body').removeClass('mobile-nav-open');

    $content.css({
      'transform': `translate(0, 0) scale(1)`,
      'transform-origin': `0 ${$content.outerHeight() * scroll_percentage}px`
    });

    $site.css({ 'height': '' });

    window.scrollTo(0, ($(document).height() - window.innerHeight) * scroll_percentage);

    var afterSlide = function () {
      $('body').removeClass('mobile-nav-expanded');
      $('body').removeClass('mobile-nav-sliding');

      $(window).trigger('navigation:closed');

      $content.css({
        'transform-origin': '0 0',
        'transform': ''
      });
    };

    if (!is_open) {
      afterSlide();
    } else {
      setTimeout(afterSlide, millis);
    }

    is_open = false;
  }

  var toggle_nav = throttle(function (e) {
    if (is_open)
      close();

    else
      open();

  }, millis + 100, { trailing: false });

  $toggles.on('click', toggle_nav);

  $(window).on('resize', function () {
    if (is_open) close();
  });

  $content.on('click', function () {
    var windowWidth = $(window).width();

    if (windowWidth < MIN_WIDTH && is_open) close();
  });

  $(document).on("ajax:loading", function () {
    if (is_open) close();
  });

  $('#flyout-nav').on('focusin', throttle(function () {
    if (!is_open) open();
  }, millis + 100, { trailing: false }));

  $(document).on("ajax:loaded", function () {
    close();
  });
}(jQuery));
