import jQuery from 'jquery'; window.$ = jQuery; window.jQuery = jQuery;
import Animate from 'animation';
import SmoothScroll, { smoothScrollTo } from 'smooth-scroll';
import "slick-carousel";
import "slick-carousel/slick/slick.css";
import Magnetic from 'magnetic';
import 'navigation';
import ScrollLabel from 'scroll-labels';
import "fake3d";
import 'tabs';
import { debounce } from 'throttle';
/*import 'ajax';*/
import 'object-fit';
import 'inverse-hover';

window.addEventListener('error', function () {
  $('body').addClass('loaded');
  $('body').addClass('error');
});

var App = (function ($) {
  function handleSelect() {
    $('.select > select').each(function () {
      if ($(this).find(':selected').get(0).hasAttribute('default')) {
        $(this).parent().addClass('default-selected')
      }
    })

    $('.select > select').on("change", function () {
      if ($(this).find(':selected').get(0).hasAttribute('default')) {
        $(this).parent().addClass('default-selected')
      } else {
        $(this).parent().removeClass('default-selected')
      }
    });
  }

  function handleFileInput() {
    $('.file-input input[type="file"]').on("change", function () {
      if (this.value) {
        $(this).removeClass("placeholder-shown");
        $(this).siblings('.file-name').html(this.value.replace(/^.*\\/, ""))
      } else {
        $(this).addClass("placeholder-shown");
        $(this).siblings('.file-name').html("Browse...")
      }
    });
  }

  function handleGallery() {
      if ($('.gallery').length) {
          var offset = $('.container').css('margin-left');
          var padding = $('.container').css('padding-left');

          $('.gallery').css("left", offset)
          $('.gallery').css("padding-left", padding)

          var timer_id;

          $(window).resize(function () {

              clearTimeout(timer_id);

              timer_id = setTimeout(function () {
                var newOffset = $('.container').css('margin-left');
                var newPadding = $('.container').css('padding-left');

                $('.gallery').css("left", newOffset);
                $('.gallery').css("padding-left", newPadding);
              }, 300);
        })
      }

      if ($('.vet').length) {
          var offset = $('.container').css('margin-left');
          var padding = $('.container').css('padding-left');

          $('.vet').css("left", offset)
          $('.vet').css("padding-left", padding)

          var timer_id;

          $(window).resize(function () {

              clearTimeout(timer_id);

              timer_id = setTimeout(function () {
                var newOffset = $('.container').css('margin-left');
                var newPadding = $('.container').css('padding-left');

                $('.vet').css("left", newOffset);
                $('.vet').css("padding-left", newPadding);
              }, 300);
          })
      }

      if ($('.contact-banner').length) {
          var offset = $('.container').css('margin-left')
          var padding = $('.container').css('padding-left');

          $('.contact-banner').find(".col-md-5 > div > div").css("margin-left", offset)
          $('.contact-banner').find(".col-md-5 > div > div").css("padding-left", padding)
          $('.contact-banner').find(".col-md-5 > div >h3").css("margin-left", offset)
          $('.contact-banner').find(".col-md-5 > div >h3").css("padding-left", padding)
          $('.contact-banner').find(".col-md-5 > div >h4").css("margin-left", offset)
          $('.contact-banner').find(".col-md-5 > div >h4").css("padding-left", padding)
          $('.contact-content').css("padding-right", padding)
          $('.contact-content').css("margin-right", offset)

          $(window).resize(function () {
              var newOffset = $('.container').css('margin-left')
              var newPadding = $('.container').css('padding-left');

              $('.contact-banner').find(".col-md-5 > div > div").css("margin-left", newOffset)
              $('.contact-banner').find(".col-md-5 > div > div").css("padding-left", newPadding)
              $('.contact-banner').find(".col-md-5 > div > h3").css("margin-left", newOffset)
              $('.contact-banner').find(".col-md-5 > div > h3").css("padding-left", newPadding)
              $('.contact-banner').find(".col-md-5 > div > h4").css("margin-left", newOffset)
              $('.contact-banner').find(".col-md-5 > div > h4").css("padding-left", newPadding)
              $('.contact-content').css("padding-right", newPadding)
              $('.contact-content').css("margin-right", newOffset)
          })
      }
  }

  function handleModal() {
      let $modal = $(".modal");
      let $open_modal = $(".open-modal, .leader-img");
      let $open_vet_modal = $(".open-vet-modal");
      let $close_modal = $(".close-modal");

      $open_modal.click(function () {
          $(".modal_content .content").empty();
          $modal.addClass("active");
          $("html").css("overflow-y", "hidden");
          let $modal_text = $(this).parent().children(".modal-text").text();
          let $modal_img = $(this).parent().children(".background-cover").css("background-image");
          $modal_img = $modal_img.replace('url(', '').replace(')', '').replace(/\"/gi, "");
          let $modal_name = $(this).parent().children("h3").text();
          let $modal_title = $(this).parent().children(".leader-title").text();

          $(".modal_content .content").append('<div class="background-cover col-md-4 col-sm-6 col-xs-8" style="background-image:url(' + $modal_img + ')"></div>' +
              '<div class="text col-md-8"><div class="leader-name">' + $modal_name + '</div>' +
              '<div class="italics">' + $modal_title + '</div>' + 
              '<p>' + $modal_text + '</p>' +
              '<span class="modal-btn">CLOSE</span></div>');

          $(".modal-btn").click(function () {
              $modal.removeClass("active");
              $("html").css("overflow-y", "scroll");
              $(".modal_content .content").empty();
          })
      })

      $open_vet_modal.click(function () {
          $(".modal_content .content").empty();
          $modal.addClass("active");
          $("html").css("overflow-y", "hidden");
          let $modal_text = $(this).parent().find(".modal-text").text();
          let $modal_img = $(this).parent().find(".background-cover").css("background-image");
          $modal_img = $modal_img.replace('url(', '').replace(')', '').replace(/\"/gi, "");
          let $modal_name = $(this).parent().find(".name").text();
          let $modal_rank = $(this).parent().find(".rank").text();
          let $modal_title = $(this).parent().find(".vet-title").text();

          $(".modal_content .content").append('<div class="background-cover col-md-4 col-sm-6 col-xs-8" style="background-image:url(' + $modal_img + ')"></div>' +
              '<div class="text col-md-8"><div class="leader-name">' + $modal_name + '</div>' +
              '<div>' + $modal_title + '</div>' +
              '<div>' + $modal_rank + '</div>' +
              '<p>' + $modal_text + '</p>' +
              '<span class="modal-btn">CLOSE</span></div>');

          $(".modal-btn").click(function () {
              $modal.removeClass("active");
              $("html").css("overflow-y", "scroll");
              $(".modal_content .content").empty();
          })
      })

      $(".close-modal").click(function () {
          $modal.removeClass("active");
          $("html").css("overflow-y", "scroll");
          $(".modal_content .content").empty();
      })
  }

  function handleTabs() {
      let $tab_div = $(".tabs div");
      if ($(".tabs").is(':visible')) {
        $tab_div.click(function () {
          
            $(this).addClass("active").siblings().removeClass("active");
            let index = $(this).index();
            $(".tab-content>div").eq(index).addClass("active").siblings().removeClass("active");
        })
      } else {
          $("#service-tabs").change(function () {
              let index = $("#service-tabs").prop('selectedIndex')
              $(".tab-content>div").eq(index).addClass("active").siblings().removeClass("active");
          })
      }
      $(window).resize(function () {
          let $active = $(".tab-content .active").index();
          if ($(".tabs").is(':visible')) {
              $('.tabs .d-flex.active').removeClass("active");
              $('.tabs .d-flex').eq($active).addClass('active');
              $tab_div.click(function () {
                  
                  $(this).addClass("active").siblings().removeClass("active");
                  let index = $(this).index();
                  $(".tab-content>div").eq(index).addClass("active").siblings().removeClass("active");
              })
          } else {
              $("#service-tabs option").eq($active).prop('selected', true);
              $("#service-tabs").change(function () {
                  let index = $("#service-tabs").prop('selectedIndex')
                  $(".tab-content>div").eq(index).addClass("active").siblings().removeClass("active");
              })
          }
      })
  }


  function handleSliders() {
      $('.slider').each(function () {

          var options = {
              arrows: false,
              dots: false,
              slidesToShow: 1,
              autoplay: true,
              autoplaySpeed: 5000
          };

          var $this = $(this);

          if ($this.data('slider-height') == "adaptive")
              $.extend(options, { adaptiveHeight: true });

          if ($this.hasClass('slider--dots'))
              $.extend(options, { dots: true });

          if ($this.hasClass('slider--arrows'))
              $.extend(options, { arrows: true });

          if ($this.data('slider-items'))
              $.extend(options, { slidesToShow: $this.data('slider-items') });

          if ($this.data('slider-fade'))
              $.extend(options, { fade: true });

          if ($this.data('slider-asnavfor'))
              $.extend(options, { asNavFor: $this.data('slider-asnavfor') });

          if ($this.data('slider-autoplay') == false) {
              $.extend(options, { autoplay: false });
          } else {
              $.extend(options, { autoplay: true, autoplaySpeed: $this.data('slider-autoplay') });
          }

          if ($this.hasClass("slider--gallery")) {
              $.extend(options, {
                  slidesToShow: 3,
                  infinite: true,
                  prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Previous</span></button>',
                  nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Next</span></button>',
                  responsive: [
                      {
                          breakpoint: 1199,
                          settings: {
                              slidesToShow: 2,
                          }
                      },
                      {
                          breakpoint: 767,
                          settings: {
                              slidesToShow: 1,
                              autoplay: false
                          }
                      },
                  ]
              });
          }

          if ($this.hasClass("slider--outreach")) {
              $.extend(options, {
                  slidesToShow: 3,
                  infinite: true,
                  prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Previous</span></button>',
                  nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Next</span></button>',
                  responsive: [
                      {
                          breakpoint: 1279,
                          settings: {
                              slidesToShow: 2,
                          }
                      },
                      {
                          breakpoint: 767,
                          settings: {
                              slidesToShow: 1,
                              autoplay: false
                          }
                      },
                  ]
              });
          }

          if ($this.hasClass("slider--veteran")) {
              $.extend(options, {
                  slidesToShow: 3,
                  infinite: true,
                  prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Previous</span></button>',
                  nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Next</span></button>',
                  responsive: [
                      {
                          breakpoint: 1279,
                          settings: {
                              slidesToShow: 2,
                          }
                      },
                      {
                          breakpoint: 767,
                          settings: {
                              slidesToShow: 1,
                              autoplay: false
                          }
                      },
                  ]
              });
          }

          if ($this.hasClass("slider--banner")) {
              $.extend(options, {
                  slidesToShow: 1,
                  infinite: true,
                  arrows: true,
                  autoplaySpeed: 7000,
                  prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Previous</span></button>',
                  nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Next</span></button>',
              });
          }

          $this.slick(options);
      });
  }

  function handleSearch() {
      var $searchToggle = $('.search-toggle'),
          $searchForm = $('#searchForm'),
          $searchButton = $('#searchButton'),
          $searchInput = $('#headerSearch'),
          open = false;

      var toggleSearch = function (e) {
          open = !open;
          $searchForm.add($searchToggle).toggleClass('active');
      };

      var blurFunction = function (e) {
          setTimeout(function () {
              if (open && e.relatedTarget != $searchButton.get(0) && document.activeElement != $searchInput.get(0) && document.activeElement != $searchButton.get(0) && document.activeElement != $searchInput.get(0)) {
                  toggleSearch();
              }
          }, 500);

      };

      $(document).mousedown(function (e) {
          if (!$searchForm.is(e.target) && !$searchToggle.is(e.target) && $searchForm.has(e.target).length === 0 && open) {
              toggleSearch();
          }
      });

      $searchButton.blur(blurFunction);
      $searchInput.blur(blurFunction);

      $searchInput.focus(function (e) {
          if (!open) { toggleSearch(); }
      });

      $searchButton.focus(function (e) {
          if (!open) { toggleSearch(); }
      });

      $searchToggle.click(function (e) {
          toggleSearch(e);
          if (open) {
              $searchInput.get(0).focus();
          }
      });
  }

  //function handleMagneticLinks() {
  //  Magnetic.clearElements();
  //  $('.tab-dots button').magnetic({ fixed: true, cursorClass: 'slick-dot' });
  //  $('.bubble.magnetic-link').magnetic({ cursorClass: 'bubble-cursor' });
  //  $('.tab-section-intro .bubble, .featured-job').magnetic({ cursorClass: 'bubble-cursor', fixed: true });
  //  $('#footer__social a').magnetic({ cursorClass: 'circle-cursor' });
  //}

  //function handleMissionBanner() {
  //  $("#expand-mission-banner").on("click", function () {
  //    $(".mission-banner").addClass("active");
  //  });
  //}

  //function handleScrollToTop() {
  //  var $balloon = $('#big-balloon');

  //  var onScroll = function () {
  //    if ($(window).scrollTop() >= 800) {
  //      $balloon.addClass('active');
  //    } else {
  //      $balloon.removeClass('active');
  //    }
  //  }

  //  onScroll();

  //  $(window).scroll(debounce(onScroll, 250));

  //  var $root = $('html, body');

  //  $balloon.click(function (e) {
  //    e.preventDefault();
  //    smoothScrollTo($root);
  //  });
  //}

  //function handleAjax() {
  //  $('#main-content a').ajaxLoad();
  //}

  function handleAnimation() {
    Animate.clearElements();

    $('.animate--video').on("animate:in", function () {
      var video = $(this).get(0);
      video.play();
    });

    $('.animate--video').on("animate:out", function () {
      var video = $(this).get(0);
      video.pause();
      video.currentTime = 0;
    });

    $('.animate').scrollAnimate();
  }

  //function handleSmoothScroll() {
  //  $('a[href^="#"]')
  //    .not('[href="#"]')
  //    .not('[href="#0"]')
  //    .not('[data-toggle="tab"]')
  //    .not('.no-scroll')
  //    .not(".JumpNav")
  //    .SmoothScroll();
  //}

  function handleLazyLoad() {
    var $lazy = $('[data-lazy]');

    $lazy.on("animate:in", function () {
      $(this).attr('src', $(this).data('lazy'))
    });

    $lazy.scrollAnimate({ displacement: -2000 });
  }

  //var interval;

  //function handleRotatingText() {
  //  clearInterval(interval);

  //  function getRandomInt(min, max) {
  //    min = Math.ceil(min);
  //    max = Math.floor(max);
  //    return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
  //  }

  //  var $ele = $(".communicate-beautifully");
  //  if ($ele.length) {
  //    var text = $.trim($ele.text())
  //      .split('.')
  //      .map(x => x ? `<span>${x}.</span>` : null)
  //      .join("");

  //    $ele.html(text);

  //    var $children = $ele.children();

  //    for (var i = 1, len = $children.length; i < len; ++i) {
  //      var idx = $.trim($children.eq(i).text().toLowerCase()) == "communicate beautifully." ? 0 : getRandomInt(1, len);

  //      var s = $children[idx];
  //      $children[idx] = $children[i];
  //      $children[i] = s;
  //    }

  //    var idx = 0;

  //    $children.eq(idx).addClass("active");

  //    $ele.on("animate:in", function () {
  //      if (!interval) {
  //        setInterval(function () {
  //          $children.removeClass("active");
  //          $children.eq(idx).addClass("active");

  //          ++idx;
  //          idx = idx < $children.length ? idx : 0;
  //        }, 2000);
  //      }
  //    });

  //    $ele.scrollAnimate();
  //  }
  //}

  //function handleObjectFit() {
  //  var edgeVersion = window.navigator.userAgent.match(/Edge\/(\d{2})\./);
  //  var edgePartialSupport = edgeVersion ? parseInt(edgeVersion[1], 10) >= 16 : false;

  //  var hasSupport = 'objectFit' in document.documentElement.style !== false;

  //  if (!hasSupport || edgePartialSupport) {
  //    function fit() {
  //      $(".bg > figure, .bg > img, .bg > span, .bg > video").objectFit();
  //    }

  //    $(window).on('ajax:loaded', function () {
  //      setTimeout(fit, 200);
  //    });

  //    $(window).on('resize', debounce(fit, 800));

  //    fit();
  //  }
  //}

  function handleSlidebars() {
    var n = new slidebars;
    n.init();
    $(".toggle-flyout").on("click", function (t) {
      t.stopPropagation();
      t.preventDefault();
      n.toggle("id-1")
    });
    $(n.events).on("opening", function () {
      $("[data-canvas]").addClass("js-close-any");
      $(".js-close-any").on("click", function (t) {
        t.stopPropagation();
        n.close()
      });
      $("#slidebar-nav a, #slidebar-nav input, #slidebar-nav button").first().focus()
    });
    $(n.events).on("closing", function () {
      $("[data-canvas]").removeClass("js-close-any")
    });
    $(".toggle-submenu").off("click").on("click", function () {
      var $submenu = $(this).parent().children(".sb-submenu");
      $submenu.toggleClass("sb-submenu-active");
      $submenu.hasClass("sb-submenu-active")
        ? ($submenu.slideDown(200),
          $submenu.parent().siblings().children(".sb-submenu-active.sb-submenu").slideUp(200),
          $submenu.parent().siblings().children(".sb-submenu-active.sb-submenu").toggleClass("sb-submenu-active"))
        : ($submenu.slideUp(200))
    });
    $(".sb-slidebar a.active").parent().parent(".sb-submenu").slideDown(0).addClass("sb-submenu-active")
  }

  function handleDropdown($list) {
    var getCmsIdFromClass = function getCmsIdFromClass(className, contentPrefix) {
      var result;
      if (undefined !== className && className.length) {
        var classList = className.split(/\s+/);
        for (var i = 0; i < classList.length; i++) {
          if (classList[i] && classList[i].length > 0 && classList[i].substring(0, contentPrefix.length) === contentPrefix) {
            result = classList[i].substring(contentPrefix.length);
            i = classList.length;
          }
        }
      }

      return result;
    };

    var getQueryStringValue = function getQueryStringValue(key) {
      return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    };

    var hoverTimer;
    var bodyContentId = getCmsIdFromClass($("body").attr('class'), 'c');
    //$list.addClass('dropdown-menu');
    var $NavItem = $list.children('li.nav-item');
    //$NavItem.children('ul').addClass('dropdown-menu');
    var $NavLinks = $NavItem.children('a');

    var closeDropdown = function (el) {
      setTimeout(function () {
        el.children('.dropdown-menu').remove();
      }, 0);
    };

    var getSubmenuContent = function (mId, currentContentId, container, callbackFunction) {
      var cacheQryval = getQueryStringValue("cache");
      $.ajax({
        type: "GET",
        url: "/CMSAjax/SubNav" + ((cacheQryval === '0') ? "?cache=0" : ""),
        data: { MenuId: mId, contentID: currentContentId },
        dataType: 'html'
      }).done(function (htmlcode) {
        container.append(htmlcode);
        callbackFunction();
      });
    };

    $NavItem.hover(function () {
      var _ = this;

      if (!$(_).hasClass("dropdown-open")) {

        getSubmenuContent(getCmsIdFromClass($(_).attr('class'), 'mid-'), bodyContentId, $(_), function () {
          $(_).siblings('li').removeClass('dropdown-open');
          closeDropdown($(_).siblings('li'));
          $(_).addClass('dropdown-open');
          clearTimeout(hoverTimer);
        });
      };

    }, function () {
      var _ = $(this);
      clearTimeout(hoverTimer);
      hoverTimer = setTimeout(function () {
        _.removeClass('dropdown-open');
        closeDropdown($list.find('li'));
      }, 0);
    });

    $(window).click(function (e) {
      if (!$(e.target).is($NavItem) && !$(e.target).closest($NavItem).length) {
        closeDropdown($NavItem);
      }
    });

    $NavLinks.focus(function () {
      var _ = $(this);
      getSubmenuContent(getCmsIdFromClass(_.parent().attr('class'), 'mid-'), bodyContentId, _.parent(), function () {
        _.parent().addClass('dropdown-open');
        _.parent().siblings().removeClass('dropdown-open');
        closeDropdown(_.parent().siblings());
      });
    });

    $NavLinks.on('touchstart', function (e) {
      e.preventDefault();
      var _ = $(this);
      getSubmenuContent(getCmsIdFromClass(_.parent().attr('class'), 'mid-'), bodyContentId, _.parent(), function () {
        _.parent().addClass('dropdown-open');
        _.parent().siblings().removeClass('dropdown-open');
        closeDropdown(_.parent().siblings());
      });
    });
  }

  return {
    //init: function () {
    //  $("#side-bar a, #return-link").ajaxLoad();

    //  $(document).on('ajax:loading', function () {
    //    Animate.clearElements();
    //  });

    //  handleScrollToTop();
    //  handleObjectFit();
    //},

    main: function () {
      handleSliders();
      handleGallery();
      handleTabs();
      handleModal();
      handleSearch();
      //handleAnimation();
      //handleLazyLoad();
      //handleSelect();
      //handleFileInput();
      //ScrollLabel();
      //handleMagneticLinks();
      //handleTabs();
      //handleMissionBanner();
      //handleAjax();
      //handleSmoothScroll();
      //handleRotatingText();

      //$('.featured-job').inverseHover();

      //$(".work-slider").workslider();
      //$(".work-slide__fake3d").fake3d();
      handleSlidebars();
      handleDropdown($("#main-navigation > ul.navigation"));

     /* $('body').addClass('loaded');*/
    },
  };
}(jQuery));

$(document).ready(function () {
  //App.init();
  App.main();

  //Header animation
  $(window).scroll(function () {
    if ($(this).scrollTop() > 0) {
      if (!$("#header").hasClass("scrolled")) {
        $("#header").addClass("scrolled");
      }

      if ($(window).width() >= 768) {
        $('#header').stop().animate({ height: '60' });
        $('#header .logo').stop().animate({ width: '180' });
      }
    }
    else {
      if ($("#header").hasClass("scrolled")) {
        $("#header").removeClass("scrolled");
      }
      if ($(window).width() >= 768) {
        $('#header').stop().animate({ height: '100' });
        $('#header .logo').stop().animate({ width: '260' });
      }
    }
  });
});

//$(document).on('ajax:loaded', function () {
//  App.main();
//});
